footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: var(--base-unit-4);
  padding: 20px 0;
  background-color: #fff;
  box-shadow: 0 -3px 4px 0 rgb(0 0 0 / 8%);
}

.text_hex {
  color: black;
  font-size: 16px;
  text-align: left;
}

.text_mlh {
  margin-bottom: 50px;
  color: black;
  font-size: 14px;
  text-align: left;
}

.logo {
  margin-right: 2px;
  margin-left: 2px;
}
