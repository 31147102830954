body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
  box-sizing: border-box;
}

#judging {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  font-weight: bold;
  text-align: center;
}

.placeholder-box {
  position: static;
  left: 990px;
  width: 70px;
  height: 70px;
  margin: 5px;
  border: 3px dashed #808080;
}

.judging-boxes {
  position: static;
  top: 0;
  left: 990px;
  width: 80px;
  height: 80px;
  margin: 5px;
  padding: 8px 14px;
  color: white;
  background: #212121;
}

.judging-box-top-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 15px;
}

.judging-box-project-id {
  margin-bottom: 12px;
  font-size: 20px;
  line-height: 0;
}

.judging-box-bottom-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  font-size: 12px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
