.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
}

.text {
  margin: 0 auto;
  padding-left: 0.5rem;
  color: #000;
  font-weight: 500;
  font-size: 1.9rem;
  user-select: none;
}
